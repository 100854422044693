import React, { useState, useEffect } from "react";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ImageOverlay,
} from "react-leaflet";
import L from "leaflet";

import { bounds, map_url, iconPath, itemsUrl } from "./constants";
import createIcon from "./utils/createIcon";
import ItemDescription from "./ItemDescription";
//import Watermark from "./Watermark";
import MyLocation from "./MyLocation.js";
import { isDesktop, onTwoFingerDrag } from './twoFingerDrag'

L.Icon.Default.imagePath = iconPath;
const tolerance = Math.abs((bounds[0][1] - bounds[1][1]) * 0.05);
const maxBoundsTolerance = 0.0015;


function Map() {
  const [ items, setItems ] = useState(null);

  useEffect(() => {
    fetch(itemsUrl)
      .then((response) => response.json())
      .then(setItems).catch(error => {
        alert(error)
      })
  }, []);

  const mapOptions = {
    attributionControl: false,
    zoomSnap: 0.1,
    zoomDelta: 0.5,
    maxBounds: [
      [bounds[0][0] + maxBoundsTolerance, bounds[0][1]],
      [bounds[1][0], bounds[1][1]],
    ],
    scrollWheelZoom: false,
    maxZoom: 21,
    zoom: 18,
    center: [45.01644717414943, 14.115035999999966],
    minZoom: 17,
    dragging: isDesktop(),
    tap: isDesktop(),
    whenCreated: (m) => {
      //m.fitBounds([
        //[bounds[0][0] - tolerance, bounds[0][1] - tolerance],
        //[bounds[1][0] + tolerance, bounds[1][1] + tolerance],
      //]);
      //m.setMinZoom(m.getZoom());
      //new Watermark({ position: "bottomright" }).addTo(m);
      new MyLocation({ position: "topleft" }).addTo(m);

      const mapEl = document.querySelector(".my-map");
      mapEl.addEventListener("touchmove", onTwoFingerDrag);
      mapEl.addEventListener("touchend", onTwoFingerDrag);
    },
  };

  return (
    <MapContainer className="my-map" {...mapOptions}>
      {false && (
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )}
      <ImageOverlay url={map_url} bounds={bounds} />
      {items && items.map((item) => (
        <Marker
          icon={createIcon(item.id)}
          key={item.id}
          position={[item.lat, item.lng]}
        >
          <Popup minWidth={200} maxWidth={220}>
            <ItemDescription item={item} />
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default Map;
