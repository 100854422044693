import React from "react";

import {imagesUrl } from './constants'

const Audio = ({ src }) => {
  return (
    <audio controls src={src}>
      <a href={src}>Audio</a>
    </audio>
  );
};

const ItemDescription = ({ item }) => {
  return (
    <div className="item">
      <div className="item__author">
        {item.author} {item.country && ` (${item.country})`}
      </div>
      <div className="item__title">
        { item.url ?
          <a href={item.url} rel="noopener noreferrer" target="_blank">
            {item.title}
          </a> : 
            item.title
        }
      </div>
      {item.img && (
        <div className="item__img">
          <img alt={item.author} src={`${imagesUrl}${item.img}`} />
        </div>
      )}
      <div className="item__description">
        {item.year}
      </div>
      {item.audio && (
        <div className="item__audio">
          <Audio src={item.audio} />
        </div>
      )}
    </div>
  );
};

export default ItemDescription;
