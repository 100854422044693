import React from 'react';

import Map from './Map.js'

function App() {
  return (
    <Map />
  );
}

export default App;
