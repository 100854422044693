import L from "leaflet";

import { bounds, iconPath } from "./constants";

const latLngBounds = L.latLngBounds(bounds);

const Interaction = (initValue = false) => {
  let finished = initValue;

  return {
    isFinished: () => {
      return finished;
    },
    setFinished: () => {
      finished = true;
    },
    setStart: () => {
      finished = false;
    },
  };
};

var positionIcon = L.icon({
    iconUrl: iconPath + 'position.svg',
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});

const MyLocation = L.Control.extend({

  onAdd: function (map) {
    let locationMarker;
    let locationCircle;
    let interaction = Interaction();
    let locateInProgress = false;
    let lastLocation = null

    const btn = L.DomUtil.create("a", "location-button");
    const bar  = L.DomUtil.create("div", "leaflet-bar");
    bar.appendChild(btn)

    function goToLocation() {
      if (lastLocation) {
        map.setView(lastLocation, map.getZoom(), { animate: true });
      }
    }

    function onLocationFound(e) {
      lastLocation = e.latlng
      L.DomUtil.removeClass(btn, 'location-button--error')
      L.DomUtil.addClass(btn, 'location-button--found')
      if (locationMarker === undefined) {
        locationMarker = L.marker(e.latlng, {icon: positionIcon}).addTo(map);
        locationCircle = L.circle(e.latlng, e.accuracy, {color: "#539de9", opacity: 0.5}).addTo(map);
      } else {
        locationMarker.setLatLng(e.latlng);
        locationCircle.setLatLng(e.latlng)
        locationCircle.setRadius(e.accuracy)
      }
      if (!latLngBounds.contains(e.latlng)) {
        if (!interaction.isFinished()) {
          alert("Your position would not visible when you are far away.");
        }
        stopLocate();
      } else if (!interaction.isFinished()) {
        goToLocation()
      }
      interaction.setFinished();
    }

    function stopLocate() {
      locateInProgress = false;
      map.stopLocate();
    }

    function onLocationError(e) {
      if (locationMarker) {
        locationMarker.removeFrom(map);
        locationCircle.removeFrom(map)
        locationMarker = undefined;
      }
      interaction.setFinished();
      alert(e.message);
      L.DomUtil.removeClass(btn, 'location-button--found')
      L.DomUtil.addClass(btn, 'location-button--error')
      stopLocate();
    }

    map.on("locationfound", onLocationFound);
    map.on("locationerror", onLocationError);

    L.DomEvent.on(btn, "click", (e) => {
      interaction.setStart();
      if (locateInProgress) {
        goToLocation()
      } else {
        locateInProgress = true;
        map.locate({ watch: true });
      }
    });
    return bar;
  },

  onRemove: function (map) {
    // Nothing to do here
  },
});

export default MyLocation;
