export const bounds = [
  [45.118473, 14.109677],
  [45.113107, 14.120395],
];
export const map_url = process.env.PUBLIC_URL + "/img/map2.jpg"

export const iconPath = process.env.PUBLIC_URL + "img/";

export const itemsUrl = process.env.PUBLIC_URL + "data/items.json";
export const imagesUrl = process.env.PUBLIC_URL + "images/";
