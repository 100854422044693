export function isDesktop() {
  let isDesktop = true;

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isDesktop = false;
  }
  return isDesktop
}

export function onTwoFingerDrag (e) {
  if (e.type === 'touchmove' && e.touches.length === 1) {
    e.currentTarget.classList.add('swiping')
  } else {
    e.currentTarget.classList.remove('swiping')
  }
}

